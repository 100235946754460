$(document).ready(function () {
    $(document).on("change", "#make-select", function (e) {
        $(".dropdown-fitguide-container").spinner().start();
        $.ajax({
            url: app.util.appendParamsToUrl(app.urls.modelDropdown, {
                make: this.value,
                makeName: this[this.selectedIndex].textContent
            }),
            dataType: "html"
        }).done(function (data) {
            if (!$.isEmptyObject(data)) {
                $(".model-select-wrapper").html(data);
                $("#year-select").addClass("d-none");
            }
            $(".dropdown-fitguide-container").spinner().stop();
        });
    });
    $(document).on("change", "#model-select", function (e) {
        $(".dropdown-fitguide-container").spinner().start();
        $.ajax({
            url: app.util.appendParamsToUrl(app.urls.yearDropdown, {
                model: this.value,
                modelName: this[this.selectedIndex].textContent,
                make: document.getElementById("make-select").value
            }),
            dataType: "html"
        }).done(function (data) {
            if (!$.isEmptyObject(data)) {
                $(".year-select-wrapper").html(data);
            }
            $(".dropdown-fitguide-container").spinner().stop();
        });
    });
    $(document).on("change", "#year-select", function (e) {
        $(".dropdown-fitguide-container").spinner().start();
        $.ajax({
            url: app.util.appendParamsToUrl(app.urls.vehicleData, {
                model: document.getElementById("model-select").value,
                make: document.getElementById("make-select").value,
                year: this.value,                
                yearName: this[this.selectedIndex].textContent,
                category: globalData.pageCategory
            }),
            dataType: "json"
        }).done(function (data) {
            if (data && !data.Error) {
                $(".dropdown-fitguide-container").spinner().stop();
                console.log("redirecting to fitguide PLP", data.redirectUrl);
                window.location = data.redirectUrl;
            }
        });
    });
    $(document).on("click", ".product-fitguideresults .merge-dropdowns", function (e) {
        $.ajax({
            url: app.util.appendParamsToUrl(app.urls.clearVehicleData),
            dataType: "json"
        }).done(function (data) {
            if (data && data.cleared) {
               $(".pdp-fitGuide-container").addClass("d-none");
            }
        });
    });
    $("[data-bg-css-fallback]").each(function () {
        let bgUrl = $(this).attr("data-bg-css-fallback");
        if (!$.isEmptyObject(bgUrl)) {
            var image = new Image();
            var elem = this;
            image.onerror = function () {
                elem.style.backgroundImage = "";
                console.log("Library image doesn't exist for element", elem.className, "using fallback from css", e);
            }
            image.src = bgUrl;
        }
    });
});
